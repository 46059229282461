// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-beach-club-js": () => import("./../src/pages/beach-club.js" /* webpackChunkName: "component---src-pages-beach-club-js" */),
  "component---src-pages-boletos-js": () => import("./../src/pages/boletos.js" /* webpackChunkName: "component---src-pages-boletos-js" */),
  "component---src-pages-historia-js": () => import("./../src/pages/historia.js" /* webpackChunkName: "component---src-pages-historia-js" */),
  "component---src-pages-hospedaje-js": () => import("./../src/pages/hospedaje.js" /* webpackChunkName: "component---src-pages-hospedaje-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nido-js": () => import("./../src/pages/nido.js" /* webpackChunkName: "component---src-pages-nido-js" */),
  "component---src-pages-private-casita-js": () => import("./../src/pages/private-casita.js" /* webpackChunkName: "component---src-pages-private-casita-js" */),
  "component---src-pages-programa-js": () => import("./../src/pages/programa.js" /* webpackChunkName: "component---src-pages-programa-js" */)
}

